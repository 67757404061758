<template>
  <div>
    <v-navigation-drawer app v-if="drawer" v-model="drawerState" color="#F7F7F7">
      <v-list nav>
        <v-list-item @click="go('/')">
          <v-list-item-icon>
            <v-icon>dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Overview</v-list-item-title>
        </v-list-item>

        <v-list-item v-if="hasRole(['ROLE_ADMIN','ROLE_READ_ONLY_ADMIN','ROLE_CUSTOMER_SUCCESS','ROLE_CUSTOMER_USER'])"
                     @click="go('/customer')">
          <v-list-item-icon>
            <v-icon>mdi-finance</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Customer Reports</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="hasRole(['ROLE_ADMIN','ROLE_READ_ONLY_ADMIN','ROLE_CUSTOMER_SUCCESS','ROLE_MNO_USER'])"
                     @click="go('/mno')">
          <v-list-item-icon>
            <v-icon>mdi-finance</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Operator Reports</v-list-item-title>
        </v-list-item>

        <v-list-group
            v-if="hasRole(['ROLE_ADMIN','ROLE_READ_ONLY_ADMIN','ROLE_CUSTOMER_SUCCESS'])"
            :value="false"
            prepend-icon="mdi-cogs"
        >
          <template v-slot:activator>
            <v-list-item-title>Admin</v-list-item-title>
          </template>

          <v-list-item @click="go('/admin/customers')">
            <v-list-item-icon>
              <v-icon>mdi-account-tie</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Customers</v-list-item-title>
          </v-list-item>
          <v-list-item @click="go('/admin/users')">
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dashboard Users</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar color="#EEEEEE" app>
      <v-app-bar-nav-icon v-if="drawer" @click="drawerState = !drawerState"/>
      <v-toolbar-title style="display: flex; flex-direction: row;">
        <div>
          <img :src="require('@/assets/images/logo.png')" style="width: 140px; height: auto; margin: 9px 0 0 0;" alt="">
        </div>
        <h3 v-if="title" style="vertical-align: middle; margin: 6px 15px 0 0;">- {{ title }}</h3>
      </v-toolbar-title>
      <slot/>
      <v-spacer></v-spacer>
      <div style="display: flex; flex-direction: column; justify-items: center;">
        <v-btn icon v-if="$route.path !== '/login'">
          <i class="material-icons" @click="logout">power_settings_new</i>
        </v-btn>
        <!--        <span style="font-size: 10px; color: grey;" v-text="'(#' + getGitHash() + ')'"/>-->
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import axios from '@/plugins/axios';
import utils from '@/mixins/utils';
import authService from '@/authService';

export default {
  name: 'NavComponent',
  mixins: [utils],
  props: {
    drawer: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      drawerState: true,
    }
  },
  created() {
    axios.interceptors.response.use(
        response => response,
        error => {
          if (error.response && error.response.status === 403) {
            this.logout();
          }
          return Promise.reject(error);
        }
    );
  },
  methods: {
    go(url) {
      // this.drawerState = false
      this.$router.push(url).catch(e => {
        if (e.name !== 'NavigationDuplicated' &&
            !e.message.includes('Avoided redundant navigation to current location')) {
          console.error(e);
        }
      });
    },
    async logout() {
      authService.logout();
      await axios.post('api/auth/logout');
      await this.routeToLogin();
    }
  }
}
</script>
