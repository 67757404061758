export default {
  init() {
    if (!localStorage.auth) {
      this.properties = {loggedIn: false, mfa: false};
    } else {
      this.properties = JSON.parse(localStorage.auth);
    }
  },
  isLoggedIn() {
    return this.properties.loggedIn;
  },
  isMFA() {
    return this.properties.mfa;
  },
  login() {
    this.properties.loggedIn = true;
    this.save();
  },
  logout() {
    this.properties.loggedIn = false;
    this.properties.mfa = false;
    localStorage.clear();
    this.save();
  },
  setMFA() {
    this.properties.mfa = true;
    this.save();
  },
  save() {
    localStorage.auth = JSON.stringify(this.properties);
  }
};
